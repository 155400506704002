import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { ExternalLink, Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const {
    publicData,
    metadata = {},
    listingFieldConfigs,
    isFieldForCategory,
    listing,
    formattedPrice,
    intl,
  } = props;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);

  const isRequesting = listing?.attributes.publicData.listingIntent === 'requesting';

  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields.map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span className={css.detailLabel}>{detail.label}</span>
            <span>{detail.value}</span>
          </li>
        ))}
        {isRequesting ? (
          <li key="availability" className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.availability" />
            </span>
            <span>{publicData.availabilityRange || '-'}</span>
          </li>
        ) : null}
        {isRequesting ? (
          <li key="budget" className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.budget" />
            </span>
            <span>{formattedPrice || '-'}</span>
          </li>
        ) : null}
        {publicData.portfolioLinks ? (
          <li key="portfolio">
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.portfolio" />
            </span>
            {publicData.portfolioLinks?.map(link => (
              <div>
                <ExternalLink href={link}>{link}</ExternalLink>
              </div>
            ))}
          </li>
        ) : null}
      </ul>
    </section>
  ) : null;
};

export default SectionDetailsMaybe;
